import { useState } from 'react';

export type ModalStore = {
  open: boolean;
  show: () => void;
  close: () => void;
}

export const useModalStore = () : ModalStore => {
  const [ open, setOpen ] = useState<boolean>( false );

  return {
    open,
    show() {
      setOpen( true )
    },
    close() {
      setOpen( false )
    }
  };
};
