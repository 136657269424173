import type { PropsWithChildren } from 'react';

export type ModalContentProps = PropsWithChildren<{}>;

export const ModalContent = ( { children }: ModalContentProps ) => {

  return (
    <div className={ 'px-6 pt-4 pb-9 overflow-auto' }>
      { children }
    </div>
  )
}
ModalContent.displayName = 'ModalContent';  